$background-color: rgb(0, 0, 0);
$alpha: 0.5;

.progressOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1051;
    background-color: rgba($background-color, $alpha);

    .spinner {
        margin: 16px auto;
    }
}
