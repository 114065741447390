.grecaptcha-badge {
    margin: 20px 0;
}

.disabled-recaptcha {
    font-style: italic;
    font-weight: bold;
    margin: 20px 5px;

    &:before {
        content: 'Recaptcha Disabled via Settings';
    }
}
