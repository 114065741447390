@import '../ui/base/variables';

.snackbar-component {
    background-color: $blue-100;
    color: $white;
    font-size: 20px;
    height: 0;
    line-height: 60px;
    overflow: hidden;
    position: fixed;
    text-align: center;
    text-transform: capitalize;
    top: 0;
    left: 0;
    transition: 0.5s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 1200;
}

.snackbar-component.show {
    height: 60px;
    visibility: visible;
}
